@import "colors";

.facts {
  padding: 7.8rem 0 10.3rem;

  @media all and (max-width: 768px) {
    padding: 5.8rem 0 8rem;
  }

  @media all and (max-width: 576px) {
    padding: 4.8rem 0 5.6rem;
  }

  @media all and (max-width: 375px) {
    padding: 3.8rem 0 3.6rem;
  }


  &__grid {
    margin-top: 4.8rem;
    display: grid;
    grid-template: auto / repeat(3, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 3.1rem;

    @media all and (max-width: 1200px) {
      grid-gap: 2rem;
    }
    @media all and (max-width: 992px) {
      grid-template: auto / repeat(2, 1fr);
    }
    @media all and (max-width: 640px) {
      max-width: 34rem;
      margin: 3.3rem auto 0;
      grid-template: auto / 1fr;
      grid-gap: 1.4rem;
    }

    &-item {
      padding: 3.4rem 4.4rem 4rem;
      box-shadow: 0 4rem 10rem rgba(196, 206, 208, 0.35);

      @media all and (max-width: 1200px) {
        padding: 2rem 2.7rem 3rem;
      }
    }

    .grid-item__desc {
      font-weight: 600;
      font-size: 2.1rem;
      line-height: 2.7rem;

      &-value {
        display: block;
        margin-top: .9rem;
        font-weight: 700;
        font-size: 2.7rem;
        line-height: 3.5rem;
        color: $t-green;

        @media all and (max-width: 375px) {
          font-size: 2.3rem;
          line-height: 2.8rem;
        }

        @media all and (max-width: 375px) {
          margin-bottom: .4rem;
        }

        &-num {
          white-space: nowrap;
          font-size: 4.8rem;
          line-height: 6.2rem;

          @media all and (max-width: 1200px) {
            font-size: 4rem;
            line-height: 5rem;
          }
          @media all and (max-width: 576px) {
            font-size: 3.2rem;
            line-height: 4.1rem;
          }
        }
      }
    }
  }
}
