@import "colors";

.footer {
  background: $t-bg-footer;
}

.footer__navigation {
  height: 13.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  line-height: 2.1rem;

  @media all and (max-width: 720px) {
    height: 17.3rem;
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
  @media all and (max-width: 576px) {
    margin: 0 auto;
    max-width: 42rem;
  }

  .navigation__menu {
    .menu__item {
      display: inline-block;
      @media all and (max-width: 768px) {
        margin-left: 0;
      }
      @media all and (max-width: 576px) {
        margin: 1.1rem 2.1rem 1.2rem 0;
        font-weight: 600;
      }
    }
    .menu__item + .menu__item {
      margin-left: 3.4rem;
      @media all and (max-width: 768px) {
        margin-left: 2rem;
      }
      @media all and (max-width: 576px) {
        margin: 1.1rem 2.1rem 1.2rem 0;
      }
    }
    .item__link {
      color: white;
      transition: .3s;
      &:hover {
        color: $t-green-hover;
      }
    }
  }
}

.footer__copyright {
  padding: 2.7rem 2rem 2.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $t-bg-footer2;

  @media all and (max-width: 576px) {
    align-items: flex-start;
  }
  .copyright__info {
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #97A4A9;
  }
  .recaptcha__info {
    display: inline-block;
    margin-top: 1.1rem;
    font-size: 12px;
    line-height: 14px;
    color: #97A4A9;
  }
  .recaptcha__link {
    white-space: nowrap;
    color: white;

    &:hover {
      color:  white;
    }
  }
}
