@import "colors";

.content-block {
  margin-top: 10rem;
  border-top: .1rem solid #E9E9E9;
  @media all and (max-width: 576px) {
     margin-top: 7.2rem;
  }
}

.content-block__container {
  max-width: 85.9rem;
  padding: 0 3.7rem;
  margin: 0 auto;

  @media all and (max-width: 768px) {
    padding: 0 2rem;
  }
}

.content-block__header-img {
  position: relative;
  @media all and (max-width: 576px) {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .header-img__img {
    object-fit: cover;
    min-height: 52.6rem;

    @media all and (max-width: 1060px) {
      height: 40rem;
      min-height: unset;
    }

    @media all and (max-width: 600px) {
      height: 20rem;
      min-height: unset;
    }
  }
}

.header-img__title {
  line-height: 7.9rem;
  color: #fff;
  font-weight: 700;
  font-size: 6.2rem;
  left: 29.1rem;
  right: 15rem;
  bottom: 6.2rem;
  position: absolute;

  @media all and (max-width:1060px) {
    top: calc(50% - 3.2rem);
    left: auto;
    right: auto;
  }

  @media all and (max-width:800px) {
    left: 2rem;
    right: 2rem;
    top: auto;
    bottom: 2.7rem;
    line-height: 3.5rem;
    font-size: 3rem;
  }
}

.content-block__title {
  margin-top: 5.4rem;
  text-align: justify;
  @media all and (max-width: 576px) {
     margin-top: 3.5rem;
  }
}

.content-block__date {
  margin-top: 1.3rem;
  display: inline-block;
  color: $text-gray;
}

.career-info, .about-info {
  margin-bottom: 10rem;

  @media all and (max-width: 576px) {
    margin-bottom: 3.4rem;
  }
}

.content-block__desc {
  margin-top: 7.3rem;
  font-size: 2.1rem;
  line-height: 3.2rem;
  text-align: justify;
  font-weight: 500;
  @media all and (max-width: 576px) {
    text-align: left;
    margin-top: 1.8rem;
    font-size: 2rem;
    line-height: 2.8rem;
  }
}

.content-block__content-info {
  font-weight: 500;
  margin-top: 3.2rem;
  text-align: justify;
  @media all and (max-width: 576px) {
    margin-top: 2.6rem;
    text-align: left;
  }


  img {
    width: 100%;
  }

  p {
    font-weight: 500;
    margin-top: 3rem;
    @media all and (max-width: 576px) {
      margin-top: 2.6rem;
    }
  }

  h3 {
    margin: 3.7rem 0 2.2rem;
    font-size: 2.6rem;
    line-height: 4rem;
    @media all and (max-width: 576px) {
     font-size: 2.2rem;
      line-height: 2.8rem;
    }
  }

  ol {
    counter-reset: olCounter;
  }
  li {
    position: relative;
    margin-bottom: .8rem;
    list-style: none;
    padding-left: 2.7rem;
    &::before {
      counter-increment: olCounter;
      content: counter(olCounter)'.';
      position: absolute;
      top: 0;
      left: 0;
      font-weight: 600;
      color: $t-green;
      margin-right: 1.5rem;
    }
  }

  a {
    border-bottom: 1px solid $t-link;
  }

  .content-info__news-desc {
    margin-top: 4.8rem;
    font-size: 2.6rem;
    line-height: 4rem;
    margin-bottom: 3rem;

    @media all and (max-width: 576px) {
      margin-top: 2.8rem;
      font-size: 2rem;
      line-height: 2.8rem;
    }
    p {
      font-weight: 700;
    }
  }

  .content-info__add-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 6.4rem;
    margin-bottom: 6.8rem;

    @media all and (max-width: 576px) {
      margin-top: 3.4rem;
      margin-bottom: 4.1rem;
    }
    a {
      border-bottom: none;
    }
  }
  .add-info__video, .add-info__document {
    width: 50%;
    display: flex;
    align-items: center;

    @media all and (max-width: 576px) {
      width: 100%;
      flex-direction: column;
      justify-content: center;
      font-size: 1.8rem;
    }
  }
  .add-info__video {
    font-weight: 600;
    .video__video-button {
      position: relative;
      background: $t-green;
      width: 6.2rem;
      height: 6.2rem;
      border: none;
      font-size: 0;
      border-radius: 50%;
      margin-right: 2rem;
      cursor: pointer;

      @media all and (max-width: 576px) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
      &:hover {
        background: $t-green-hover;
      }
      &:active {
        background: $t-green-active;
      }
      &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        top: calc(50% - .7rem);
        left: calc(50% - .3rem);
        border: .75rem solid transparent;
        border-left: 1.2rem solid white;
      }
    }

    .video__video-link {
      color: $text-black;
      &:hover {
        color: $text-black;
      }
    }
  }
  .add-info__document {
    @media all and (max-width: 576px) {
      margin-top: 4.4rem;
    }
    .document__icon {
      margin-right: 2rem;
      max-width: 3.7rem;
      display: flex;
      align-items: center;

      @media all and (max-width: 576px) {
        margin-right: 0;
      }
    }
    .document__info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media all and (max-width: 576px) {
        align-items: center;
      }
      .info__link {
        font-weight: 600;
        line-height: 2.7rem;
        border-bottom: 1px solid $t-link;
        @media all and (max-width: 576px) {
          margin-top: 1.5rem;
        }
      }
      .info__size {
        font-size: 1.5rem;
        line-height: 2.3rem;
        color: #919191;

        @media all and (max-width: 576px) {
          margin-top: .8rem;
        }
      }
    }
  }

  &.career-info {
    text-align: left;
    h3  {
      font-size: 3.8rem;
      line-height: 4.9rem;

      @media all and (max-width: 576px) {
        font-size: 2.4rem;
        line-height: 2.8rem;
      }
    }
    ul {
      margin-top: 3.2rem;

      @media all and (max-width: 576px) {
        margin-top: 1.4rem;
      }
    }

    li {
      position: relative;
      padding-left: 3rem;
      font-weight: 500;

      @media all and (max-width: 576px) {
        padding-left: 2rem;
      }

      &::before {
        content: '';
        display: block;
        width: .5rem;
        height: .5rem;
        border-radius: 50%;
        background: $t-green;
        top: 1.2rem;
        left: 0;

        @media all and (max-width: 576px) {
          width: .4rem;
          height: .4rem;
        }
      }
    }
  }

  .content-info__job-offers {
    margin-top: 3.7rem;
    @media all and (max-width: 576px) {
      margin-top: 2.1rem;
    }

    .job-offers__item + .job-offers__item {
      margin-top: 2rem;
      @media all and (max-width: 576px) {
        margin-top: 1.1rem;
      }
    }

    .job-offers__item {
      background: #EFF3F4;

      &-title {
        position: relative;
        display: flex;
        padding: 0 3.8rem 0 4.3rem;
        height: 11.1rem;
        font-weight: 600;
        align-items: center;
        border-bottom: 1px solid #D1DADC;
        cursor: pointer;

        @media all and (max-width: 576px) {
          font-weight: 600;
          padding: 0 2rem;
          height: 6.9rem;
        }

        &.title--active {
          &::after {
            transform: rotate(0);
          }
        }

        &::before {
          content: '';
          display: block;
          width: .5rem;
          height: 3.4rem;
          position: absolute;
          left: 0;
          top: calc(50% - 1.7rem);
          background: $t-green;
        }

        &::after {
          content: '';
          display: block;
          width: 3.3rem;
          height: 3.3rem;
          position: absolute;
          right: 3.9rem;
          top: calc(50% - 1.65rem);
          border-radius: 50%;
          transform: rotate(180deg);
          background: $t-green url('../img/job-arrow.svg') center center/auto no-repeat;

          @media all and (max-width: 576px) {
            width: 2.7rem;
            height: 2.7rem;
            right: 2rem;
            top: calc(50% - 1.35rem);
          }
        }
      }

      &-job-info {
        padding: 0 4.3rem 4.7rem;
        display: none;

        @media all and (max-width: 576px) {
          padding: 0 2rem 3.4rem;
        }
      }
    }
  }

}

.solutions-wrapper {
  @media all and (max-width: 576px) {
    width: calc(100% + 4rem);
    margin-left: -2rem;
  }
}


//Tank cleaning tabs
.solutions-block {
  margin-top: 6.2rem;
  background-color: #EFF3F4;
  padding: 7.6rem 2rem 9.4rem;

  @media all and (max-width: 600px) {
    padding-top: 3rem;
    padding-bottom: 2.4rem;
  }
  @media all and (max-width: 576px) {
    margin-top: 4.2rem;
  }

  &__title {
    text-align: center;
    font-size: 3.8rem;
    line-height: 4.9rem;

    @media all and (max-width: 600px) {
      font-size: 2.4rem;
      line-height: 3.5rem;
      margin-top: 0;
      text-align: left;
    }
  }


  .content-block__tabs-links {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #e8e8e8;

    .tabs-links__item {
      font-weight: 600;
      margin-bottom: -0.2rem;

      @media all and (max-width: 600px) {
        font-size: 1.6rem;
      }
    }
  }
}

.tabs-solutions-block {
  margin-top: 5.7rem;
  background-color: #EFF3F4;
  height: max-content;

  @media all and (max-width: 576px) {
    margin-top: 2.2rem;
  }

  .tabs__item {
    position: relative;
    display: none;
    width:100%;
    align-content: space-around;

    &-inner {
      max-width: 113.4rem;
      margin: 0 auto;
      display: grid;
      grid-template: auto / repeat(4, 1fr);
      grid-gap: 3rem;

      @media all and (max-width: 1200px) {
        grid-gap: 2rem;
      }
      @media all and (max-width: 1024px) {
        grid-template: auto / repeat(2, 1fr);
      }
      @media all and (max-width: 576px) {
        grid-template: auto / 1fr;
        grid-gap: 1.5rem;
      }
    }

    .solution-info {
      padding: 3.2rem;
      font-weight: 600;
      font-size: 2.1rem;
      line-height: 3.2rem;
      color: #000;
      background-color: white;

      @media all and (max-width: 1200px) {
        padding: 2rem;
      }

      @media all and (max-width: 576px) {
        font-size: 1.8rem;
        line-height: 2.8rem;
        padding: 2.8rem 3.1rem 2.8rem 1.9rem;
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
  .tabs__item.current-tab {
    display: flex;
  }

  .item__map {
    height: 48.6rem;

    @media all and (max-width: 768px) {
      height: 32rem;
    }
    @media all and (max-width: 576px) {
      height: 24rem;
      margin-left: -2rem;
      margin-right: -2rem;
    }
  }
}


.media {
  margin: 3.3rem 0;
  display: grid;
  grid-template: auto / repeat(2, 1fr);
  grid-gap: 3rem;

  @media all and (max-width: 768px) {
    grid-template: auto / 1fr;
  }

  &__wrapper {
    position: relative;
    @media all and (max-width: 768px) {
      max-width: 64rem;
      margin: 0 auto;
      grid-gap: 2.7rem;
    }

    &.video--play {

      .media__video::before {
        opacity: 0;
        height: 0;
      }
      .video__video-button  {
        display: none;
      }
      .media__title {
        @media all and (min-width: 768px) {
          display: none;
        }
      }
    }
  }

  &__title {
      display: inline-block;
      position: absolute;
      bottom: 23%;
      left: 0;
      right: 0;
      text-align: center;
      cursor: pointer;
      z-index: 3;
      color: #fff;
      font-size: 2.8rem;

      @media all and (max-width: 1024px) {
        bottom: 17%;
      }

      @media all and (max-width: 768px) {
        font-weight: 700;
        margin-bottom: 1.3rem;
        position: relative;
        font-size: 2.4rem;
        line-height: 3.5rem;
        color: $text-black;
      }

      &-link {
        color: white;

        @media all and (max-width: 768px) {
          color: $text-black;
        }
        &:hover {
          color: white;
          @media all and (max-width: 768px) {
            color: $text-black;
          }
        }
      }
    }

  .media__video, .media__presentation {
    height: 100%;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: #021C22;
      opacity: .5;
      transition: .2s;
    }

    video {
      width: 100%;
      height: 100%;
    }

    .video__video-button {
      position: absolute;
      left: calc(50% - 3.1rem);
      top: calc(50% - 3.1rem);
      background: $t-green;
      width: 6.2rem;
      height: 6.2rem;
      border: none;
      font-size: 0;
      border-radius: 50%;
      z-index: 3;
      margin-right: 2rem;
      cursor: pointer;

      @media all and (max-width: 576px) {
        margin-right: 0;
        margin-bottom: 1rem;
        left: calc(50% - 2rem);
        top: calc(50% - 2rem);
        width: 4rem;
        height: 4rem;
      }
      &:hover {
        background: $t-green-hover;
      }
      &:active {
        background: $t-green-active;
      }
      &::before {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        top: calc(50% - .7rem);
        left: calc(50% - .3rem);
        border: .75rem solid transparent;
        border-left: 1.2rem solid white;

        @media all and (max-width: 576px){
          top: calc(50% - .5rem);
          left: calc(50% - .2rem);
          border: .6rem solid transparent;
          border-left: .8rem solid #fff;
        }
      }
    }
  }

  .media__presentation {
    // height: 37.4rem;
    margin-top: -3px;

    &-button {
      position: absolute;
      left: calc(50% - 3.1rem);
      top: calc(50% - 3.1rem);
      background: $t-green;
      width: 6.2rem;
      height: 6.2rem;
      border: none;
      font-size: 0;
      border-radius: 50%;
      z-index: 3;
      margin-right: 2rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      @media all and (max-width: 576px) {
        margin-right: 0;
        margin-bottom: 1rem;
        left: calc(50% - 2rem);
        top: calc(50% - 2rem);
        width: 4rem;
        height: 4rem;
      }
      &:hover {
        background: $t-green-hover;
      }
      &:active {
        background: $t-green-active;
      }

      svg {
        @media all and (max-width: 576px) {
          width: 16px;
        }
      }
    }

    &-img {
      max-width: 100%;
    }
  }
}

.content-block__container {
  .content-block__go-back {
    position: relative;
    margin-top: 6.7rem;
    margin-bottom: 8.1rem;
    display: inline-flex;
    height: 5.6rem;
    align-items: center;
    padding-left: 6.7rem;
    padding-right: 2.9rem;

    @media all and (max-width: 576px) {
      margin-top: 3.5rem;
      margin-bottom: 4.7rem;
    }

    &::before {
      content: '';
      display: block;
      width: 2.2rem;
      height: 1.2rem;
      position: absolute;
      top: calc(50% - .6rem);
      left: 2.3rem;
      background: url('../img/go-back-arrow.svg') center center no-repeat;
      background-size: contain;
    }

  }
}
