@import "colors";

.contacts-block {
  border-bottom: 1px solid #E8E8E8;
}

.content-block__tabs-links {
  margin-top: 1.8rem;
}

.tabs-contact-block {
  margin: 5rem 0;

  @media all and (max-width: 576px) {
    margin: 3.5rem 0 2rem;
  }

  .tabs__item {
    position: relative;
    display: none;
  }
  .tabs__item.current-tab {
    display: grid;
  }

  .item__map {
    height: 48.6rem;

    @media all and (max-width: 768px) {
      height: 32rem;
    }
    @media all and (max-width: 576px) {
      height: 24rem;
      margin-left: -2rem;
      margin-right: -2rem;
    }
  }

  .item__contact-info {
    width: 67.5rem;
    position: absolute;
    top: calc(50% - 17.8rem);
    right: 6.3rem;
    background: white;
    z-index: 1;
    padding: 3.1rem 4.9rem 6.1rem;

    @media all and (max-width: 1200px) {
      width: unset;
      max-width: 48rem;
      top: calc(50% - 17.8rem);
    }
    @media all and (max-width: 992px) {
      max-width: 39rem;
      padding: 3rem 2.5rem;
      right: 2rem;
    }
    @media all and (max-width: 768px) {
      position: relative;
      max-width: 100%;
      padding: 3rem 0;
      top: 0;
      right: 0;
    }
    @media all and (max-width: 576px) {
      padding: 1.5rem 0;
    }

    .contact-info__place-title {
      font-size: 3rem;
      line-height: 6.6rem;
      font-weight: 700;

      @media all and (max-width: 576px) {
        font-size: 2.2rem;
        line-height: 4.8rem;
      }
    }
    .contacts-info__contacts {
      .contacts__address, .contacts__phone, .contacts__fax, .contacts__email {
        display: flex;
        margin-top: 1.5rem;

        @media all and (max-width: 576px) {
          display: flex;
          flex-direction: column;
        }
      }

      .contacts-info__content {
        @media all and (min-width: 769px) and (max-width: 992px) {
          font-size: 1.8rem;
        }
      }

      .address__title, .phone__title, .fax__title, .email__title {
        width: 11.4rem;
        color: #7B7B7B;

        &_wide {
          width: 17.1rem;
        }
      }
      .address__content, .phone__content, .fax__content, .email__content {
        .phone__link, .fax__link {
          color: $text-black;
          &:hover {
            color: $text-black;
          }
        }
        .email__link {
          line-height: 2.8rem;
          border-bottom: 1px solid $t-link;
        }
      }
    }
  }
}

.tabs-links__item {
  display: inline-flex;
  align-items: center;
  height: 4.6rem;
  font-weight: 600;
  font-size: 2.1rem;
  margin-right: 3.2rem;
  border-bottom: .2rem solid transparent;
  color: #919191;
  cursor: pointer;

  &:last-of-type {
    margin-right: 0;
  }

  @media all and (max-width: 576px) {
    font-size: 1.6rem;
    height: 3.5rem;
    margin-right: 2.5rem;
  }
}

.tabs-links__item.current-tab {
  border-bottom: .2rem solid $t-green;
  transition: .2s;
  color: $text-black;
}



.feedback-block__feedback-wrapper {
  padding: 6.3rem 0 7.4rem;
  background: $t-bg-light-gray;
  margin-bottom: 5.9rem;

  @media all and (max-width:576px) {
    padding: 2.4rem 0 3.4rem;
    margin: 0 -2rem 0 -2rem;

  }

  .feedback-wrapper__title {
    font-size: 3.8rem;
    line-height: 4.4rem;
    padding: 0 2rem;

    @media all and (max-width: 576px) {
      font-size: 2.4rem;
      line-height: 2.8rem;
      text-align: left;
    }
  }
}

.feedback-wrapper__form-wrapper {
  max-width: 78.4rem;
  margin: 4.8rem auto 0;

  @media all and (max-width: 992px) {
    width: 100%;
    padding: 0 3rem;
  }
  @media all and (max-width:768px) {
    padding: 0 2rem;
  }
  @media all and (max-width:576px) {
    margin-top: 2.3rem;
  }
}

.form__input-wrapper {
  display: grid;
  grid-template: auto / repeat(2, 1fr);
  grid-column-gap: 3rem;

  @media all and (max-width: 768px) {
    grid-column-gap: 2rem;
  }
  @media all and (max-width: 576px) {
    grid-template: auto / 1fr;
    grid-row-gap: .7rem;
  }
}

.form__input-field, .form__textarea {
  width: 100%;
  background: white;
  border: none;
  padding: 1.9rem 3.1rem 2rem;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.9rem;
  color: $text-black;
  border-radius: 0;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: $text-gray;
  }

  @media all and (max-width: 576px) {
    padding: 1.6rem 2.5rem 1.8rem;
  }
}

.sec-field {
  display: none;
}

.form__input-field.invalid, .form__textarea.invalid {
  border: 1px solid #E23A3A;
  &::placeholder {
    color: #E23A3A;
  }
}

.form__textarea {
  margin-top: 2.8rem;
  min-height: 29.6rem;
  vertical-align:top;
  resize: none;

  @media all and (max-width: 768px) {
    margin-top: 2rem;
    min-height: 19.1rem;
  }
  @media all and (max-width: 576px) {
    margin-top: .7rem;
  }
}

.form__button {
  padding: 1.3rem 5.9rem 1.4rem;
  margin-top: 3rem;
  height: 5.6rem;

  @media all and (max-width: 576px) {
    margin-top: 1.8rem;
  }
  @media all and (max-width: 375px) {
    width: 100%;
  }
}

.form__privacy {
  margin-top: 1.9rem;
  font-size: 1.8rem;
  line-height: 2.9rem;
}

.form__checkbox {
  display: none;
}
.form__checkbox:checked + .form__privacy-label::before {
  background: white url('../img/check.svg') center center no-repeat;
  background-size: auto;
}

.form__privacy-label {
  position: relative;
  padding-left: 3.1rem;
  &::before {
    content: '';
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background: white;
    margin-right: 1.1rem;
    position: absolute;
    left: 0;
    top: .3rem;
  }
  .privacy-label__link {
    color: $t-green;
    &:hover, &:active, &:focus {
      color: $t-green-hover;
      outline: none;
    }
  }
}


