@import "colors";

.slider-navigation {
  position: relative;
  margin-top: 8.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  @media all and (max-width: 576px) {
    margin-top: 6.1rem;
  }

  &__arrow {
    display: block;
    width: 5.6rem;
    height: 5.6rem;
    border: .1rem solid rgba(255, 255, 255, .4);
    border-radius: 50%;
    background: transparent url('../img/slider-arrow.svg') center center/auto no-repeat;
    font-size: 0;
    cursor: pointer;

    &.arrow--prev {
      margin-right: 2.3rem;
    }

    &.arrow--next {
      margin-left: 2.3rem;
      transform: rotate(180deg);
    }
  }

  &__dots {
    display: flex;
    align-items: center;
    justify-content: center;

    &-item {
      margin: 0 .7rem;
      display: block;
      width: .8rem;
      height: .8rem;
      border-radius: 50%;
      background: rgba(255, 255, 255, .4);
      cursor: pointer;

      &.item--current {
        background: white;
      }
    }
  }
}

.slick-slider {
  *:focus {
    outline: none;
  }
}


.slider-navigation.navigation--services {
  .slider-navigation__arrow {
    background: #B4C1C3 url('../img/slider-tank-arrow.svg') center center/auto no-repeat;

    @media all and (max-width: 576px) {
      width: 4rem;
      height: 4rem;
    }

    &:hover {
      @media all and (min-width: 768px) {
        background: $t-green url('../img/slider-tank-arrow.svg') center center/auto no-repeat;
      }

    }
  }
  .slider-navigation__dots {
    &-item {
      background: #B4C1C3;
      &.item--current {
        background: $t-green;
      }
    }
  }
}

