@import "colors";

.error-block {
  margin-top: 10rem;
  border-top: 1px solid $t-border-gray;

  @media all and (max-width: 576px) {
    margin-top: 7.2rem;
  }
}

.error-block__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 11rem);

  @media all and (max-width: 576px) {
   height: calc(100vh - 8rem);
  }

  .content__error {
    font-size: 30rem;
    line-height: 19.5rem;
    font-weight: 800;
    color: #072833;

    @media all and (max-width: 576px) {
      font-size: 15rem;
      line-height: 10rem;
    }
  }
  .content__error-desc {
    margin-top: 6rem;
    font-size: 3.9rem;
    line-height: 5rem;
    color: #072833;
    font-weight: 600;

    @media all and (max-width: 576px) {
      margin-top: 4rem;
      font-size: 2.8rem;
      line-height: 3.6rem;
    }
  }
  .content__link-wrap {
    margin-top: 6rem;

    @media all and (max-width: 576px) {
      margin-top: 4rem;
    }
    .content__home-link {
      padding: 1.4rem 3.5rem 1.4rem;
      font-weight: 600;
      &:hover {
        color: white;
      }
    }
  }
}

