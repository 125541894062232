@import "colors";

.services-block {
  margin: 0 auto;
  padding: 5.7rem 0 7.5rem;
  font-size: 2.1rem;
  line-height: 3.2rem;
  @media all and (max-width: 576px) {
    padding: 3.5rem 0 3.5rem;
  }
}
.services-block.services-block--page {
  padding: 1rem 0 0;
  margin-bottom: 8.3rem;
  @media all and (max-width: 576px) {
    margin-bottom: 2.8rem;
  }
}
.services-block__services-grid {
  margin-top: 4.8rem;
  display: grid;
  grid-template: auto / repeat(2, 1fr);
  grid-gap: 3.1rem;

  @media all and (max-width: 992px) {
    grid-gap: 2.1rem;
  }

  @media all and (max-width: 576px) {
    margin-top: 2.5rem;
    grid-template: auto / 100%;
    grid-gap: 2.1rem;
  }
}

.services-grid__item {
  position: relative;
  padding: 4.9rem 5.2rem 6.3rem 5.8rem;
  box-shadow: 0 4rem 10rem rgba(196, 206, 208, 0.35);

  @media all and (max-width: 992px) {
    padding: 2.7rem 2.1rem 2.7rem 2.7rem;
  }

  &::before {
    content: '';
    display: block;
    height: .5rem;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: $t-green;
    z-index: -1;
    transition: .6s;
  }

  .item__title {
    display: inline-block;
    position: relative;
    padding-right: 9.5rem;
    font-weight: 700;
    font-size: 2.8rem;
    line-height: 3.6rem;
    z-index: 1;
    transition: .5s;

    @media all and (max-width: 992px) {
      font-size: 2.4rem;
      line-height: 2.8rem;
      padding-right: 4.2rem;
    }


    &-link {
      display: inline-block;
      color: $text-black;

      &:hover {
        color: white;
      }
    }
  }

  .item__desc {
    margin-top: 2.5rem;
    max-width: 37rem;
    color: #333A3C;

    @media all and (max-width: 992px) {
      font-size: 1.8rem;
      line-height: 2.8rem;
    }
    @media all and (max-width: 576px) {
      margin-top: 1.6rem;
      margin-right: 1.9rem;
    }

  }

  .item__link {
    position: absolute;
    display: block;
    width: 4.3rem;
    height: 4.3rem;
    top: 4.9rem;
    right: 5.2rem;
    font-size: 0;
    border-radius: 50%;
    background: #01AE7A url('../img/services-link-arrow.svg') center center/auto no-repeat;
    z-index: 1;

    @media all and (max-width: 992px) {
      width: 3.1rem;
      height: 3.1rem;
      right: 2.1rem;
      top: 2.6rem;
    }
  }

  &:hover {
    background: linear-gradient(rgba(3, 27, 35, .7), rgba(3, 27, 35, .7)), url('../img/bg-services-item.jpg') center center no-repeat;
    background-size: cover;
    @media all and (max-width: 768px) {
      background: none;
    }

    .item__title-link, .item__desc {
      color: white;

      @media all and (max-width: 768px) {
        color: $text-black;
      }
    }
  }

}

