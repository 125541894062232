@import "colors";

.customers-block {
  @media all and (max-width: 576px) {
   background: $t-bg-light-gray;
  }
}
.customers-block.customers-block--page {
  margin-top: 6.8rem;
  @media all and (max-width: 576px) {
    margin-top: 3.8rem;
  }

  .customes-block__customers-wrapper {
    padding: 7.5rem 0 7.4rem;
    @media all and (max-width: 576px) {
      padding: 2.3rem 0;
    }
  }
  .customers-wrapper__customers-grid {
    margin-top: 0;
  }
}

.customes-block__customers-wrapper {
  background: $t-bg-light-gray;
  padding: 6.1rem 0 7.4rem;
  margin-bottom: 3.5rem;

  @media all and (max-width: 576px) {
    padding: 3.1rem 0 3.5rem;
    margin:0 -2rem;
  }
}
.customers-wrapper__customers-grid {
  margin-top: 4.2rem;
  display: grid;
  grid-template: auto / repeat(4, 1fr);
  grid-gap: .7rem;
  @media all and (max-width: 992px) {
    grid-template: auto / repeat(3, 1fr);
  }
  @media all and (max-width: 768px) {
    grid-template: auto / repeat(2, 1fr);
  }
  @media all and (max-width: 480px) {
    margin-top: 2.4rem;
    grid-template: auto / 100%;
    grid-gap: 1.4rem;
  }
}

.customers-grid__item {
  display: flex;
  height: 27.8rem;
  justify-content: center;
  align-items: center;
  background: white;

  @media all and (max-width: 576px) {
    height: 18.2rem;
  }

  .item__customers-logo {
    max-width: 19.7rem;
    max-height: 14rem;
    object-fit: cover;
  }
}
