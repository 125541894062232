@import "colors";

.modal {
  position: relative;
  display: none;
  z-index: 100;

  .fancybox-close-small, .modal__footer-close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 5rem;
    top: 5rem;
    border: .2rem solid white;
    border-radius: 50%;
    opacity: 1;
    z-index: 1;

    @media all and (max-width: 576px) {
      width: 2.9rem;
      height: 2.9rem;
      padding: .5rem;
    }

    &::before {
      content: '';
      display: block;
      width: 1.1rem;
      height: 1.1rem;
      background: url('../img/close.svg') center center no-repeat;
      background-size: cover;

      @media all and (max-width: 1066px) {
        background: url('../img/close-black.svg') center center no-repeat;
        background-size: cover;
      }
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      bottom: -2.6rem;
      left: 0;
      text-align: center;
      text-transform: uppercase;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: white;
      @media all and (max-width: 1066px) {
        display: none;
      }
    }

    svg {
      display: none;
    }
  }

  * {
    &:focus {
      outline: none;
    }
  }
}

.modal__footer {
  position: relative;
}

.modal__footer-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0, -50%);
}

.modal.modal-thank {
  margin-top: 0;
  width: 45rem;
  text-align: center;
  padding: 4rem;

  @media all and (max-width: 490px) {
    width: unset;

  }
  .fancybox-close-small {
    right: 1rem;
    top: 1rem;
    border: none;
    &::before {
      background: url('../img/close-black.svg') center center no-repeat;
      background-size: cover;
    }
  }
}


.modal-privacy, .modal-thank {
  margin: 7.2rem 1rem 1.4rem;
  max-width: 90rem;
  padding: 4rem 8.1rem 4.7rem 5.6rem;
  overflow: unset;

  @media all and (max-width: 768px) {
    padding: 3rem 3rem 4rem;
    margin-top: 1.4rem;
  }
  @media all and (max-width: 576px) {
    padding: 2rem 2rem 3.3rem;
  }

  .fancybox-close-small, .modal__footer-close {
    position: absolute;
    right: -6.6rem;
    top: 3.5rem;

    @media all and (max-width: 1066px) {
      right: 1rem;
      top: 1rem;
      border: .2rem solid $t-bg-footer;
    }
  }

  .modal__footer-close {
    margin-top: 2rem;
    margin-bottom: 0;
    margin-left: calc(50% - 5rem);
    width: 4.4rem;
    height: 4.4rem;
    padding: 1rem;
    position: relative;
    border: .2rem solid $t-bg-footer;
    top: unset;
    right: unset;
    background: white;
    font-size: 0;
    cursor: pointer;

    &::before {
      background: url('../img/close-black.svg') center center no-repeat;
      background-size: cover;
    }

    @media all and (max-width: 576px) {
      width: 2.9rem;
      height: 2.9rem;
      padding: .5rem;
    }
    &::after {
      color: $t-bg-footer;
      bottom: unset;
      left: unset;
      top: 1.3rem;
      right: -5.7rem;
      font-weight: 700;

      @media all and (max-width: 1066px) {
        display: block;
      }
      @media all and (max-width: 576px) {
        top: .5rem;
        right: -3.7rem;
      }
    }
  }
}

.fancybox-slide--html {
  padding: 0;
}

.fancybox-is-open .fancybox-bg {
  background: $t-bg-footer;
  opacity: .5;
}

.modal__content {
  .content__desc {
    margin-top: 3.7rem;
    font-size: 2.6rem;
    line-height: 3.5rem;
    font-weight: 700;

    @media all and (max-width: 576px) {
      font-size: 2rem;
      line-height: 2.7rem;
    }
  }

  strong {
    font-size: 2.6rem;

    @media all and (max-width: 576px) {
      font-size: 2rem;
    }
  }

  p, h4 {
    margin-top: 3.7rem;

    @media all and (max-width: 576px) {
      margin-top: 3rem;
    }
  }
  h4 {
    font-size: 2.6rem;
    line-height: 3.5rem;
  }

}

.modal__scroll-top {
  position: sticky;
  margin-bottom: -4.4rem;
  bottom: 6.1rem;
  height: 4.4rem;

  @media all and (max-width: 576px) {
    height: 2.9rem;
    bottom: 3.3rem;
  }

}

.scroll-top__button {
  position: absolute;
  top: 0;
  right: -14.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.4rem;
  height: 4.4rem;
  border: .2rem solid white;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  z-index: 110;

  @media all and (max-width: 1066px) {
    right: -7.1rem;
    border: none;
    background: $t-green;
  }
  @media all and (max-width: 768px) {
    right: -2rem;
  }
  @media all and (max-width: 576px) {
    right: -1rem;
    width: 2.9rem;
    height: 2.9rem;
  }
  &::after {
    content: 'up';
    display: block;
    width: 100%;
    position: absolute;
    bottom: -2.6rem;
    left: 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: white;
    @media all and (max-width: 1066px) {
      display: none;
    }
  }
}

.modal-presentation, .modal-video {
  width: 100%;
  height: 100%;
  background: $t-bg-footer;
  margin: 0;

  @media all and (max-width: 768px) {
    padding: 0 3rem;
  }
  @media all and (max-width: 576px) {
    padding: 0 2rem;
  }

  .modal__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 90rem;
    min-height: 100%;
  }

  .content__video-title {
    width: 100%;
    color: white;
  }

  .content__video-wrap {
    @media all and (max-width: 576px) {
      margin-left: -2rem;
      margin-right: -2rem;
    }
  }

  .content__video {
    margin-top: 2.5rem;
    width: 100%;
    &:focus {
      outline: none;
    }
  }

  .fancybox-close-small {
    @media all and (max-width: 768px){
      top: 1.5rem;
      right: 1.5rem;
    }
    &::before {
      background: url('../img/close.svg') center center no-repeat;
      background-size: cover;
    }
  }
}

.pdf-modal {
  display: none;
  width: 100%;
  height: 100%;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  background-color: #04252c;

  @media all and (min-width: 1200px) {
    padding-top: 5.1rem;
  }

  &__wrapper {
    height: 100%;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2.4rem;
  }

  &__container {
    max-width: 100rem;
    height: 100%;
    height: calc(100% - 6rem);
    margin: 0 auto;
  }

  &__content {
    width: 100%;
    max-width: 100.2rem;
    height: 100%;
    border: 0;

    @media all and (min-width: 1200px) {
      position: absolute;
      top: 0;
    }
  }

  .fancybox-button {
    display: none;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.4rem;
    height: 4.4rem;
    border: 2px solid transparent;
    border-radius: 50%;
    opacity: 1;
    z-index: 1;

    &:hover {
      cursor: pointer;
    }

    &::before {
      content: '';
      display: block;
      background-size: cover;
    }

    svg {
      display: none;
    }
  }

  &__close {
    border-color: #fff;
    background: transparent;

    &::before {
      width: 1.1rem;
      height: 1.1rem;
      background: url('../img/close.svg') center center no-repeat;
    }
  }

  &__download {
    border-color: #00ae7a;
    background: #00ae7a;

    &::before {
      width: 1.4rem;
      height: 1.7rem;
      background: url('../img/download.svg') center center no-repeat;
    }
  }
}

.fancybox-slide--image.fancybox-slide--current {
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;

  .fancybox-content {
    position: relative;
    transform: unset !important;
    .fancybox-image {
      position: relative;
    }
  }

  .fancybox-close-small {
    position: fixed;
    top: 5rem;
    right: 5rem;
    display: block;
    width:  4.4rem;
    height: 4.4rem;
    border: .2rem solid #04252C;
    border-radius: 50%;
    opacity: 1;

    @media all and (max-width: 576px){
      width: 2.9rem;
      height: 2.9rem;
      top: 2.5rem;
      right: 2rem;
      padding: .3rem;
    }
    @media all and (max-height: 576px) {
      background: white;
    }

    path {
      fill: #04252C;
      opacity: 1;
    }
  }

}

.fancybox-container {
  .fancybox-toolbar {
    opacity: 1;
  }
  .fancybox-button--download {
    position: fixed;
    top: 5rem;
    left: 5rem;
    width: 4.4rem;
    height: 4.4rem;
    background: $t-green;
    border-radius: 50%;
    opacity: 1;
    visibility: visible;

    &:focus {
      outline: none;
    }


    @media all and (max-width: 576px) {
      top: 2.5rem;
      left: 2rem;
      width: 2.9rem;
      height: 2.9rem;
      padding: .4rem;
    }

    &::after {
      content: 'Download';
      position: absolute;
      top: .8rem;
      left: 6rem;
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.9rem;
      color: $text-black;
      font-family: 'FuturaPT', 'Sans-Serif';

      @media all and (max-width: 576px) {
        font-size: 1.35rem;
        line-height: 2.2rem;
        top: .4rem;
        left: 3.9rem;
      }
    }
    svg {
      path {
        fill: white;
      }
    }

  }
}


.fancybox-navigation {
  display: none;
  .fancybox-button--arrow_left, .fancybox-button--arrow_right {
    visibility: visible;
    opacity: 1;
    top: unset;
    bottom: 4.8rem;
    width: 5.7rem;
    height: 5.7rem;
    border-radius: 50%;
    padding: 0;
    background: $t-green url('../img/slider-tank-arrow.svg') center center/auto no-repeat;
    cursor: pointer;

    @media all and (max-width: 576px) {
      width: 4rem;
      height: 4rem;
    }

    &:disabled {
      background: #B4C1C3 url('../img/slider-tank-arrow.svg') center center/auto no-repeat;
      &:hover {
        background: #B4C1C3 url('../img/slider-tank-arrow.svg') center center/auto no-repeat;
      }
    }

    &:hover, &:active {
      background: $t-green-hover url('../img/slider-tank-arrow.svg') center center/auto no-repeat;
    }

    svg {
      display: none;
    }
  }
  .fancybox-button--arrow_left {
    left: 5rem;
    @media all and (max-width: 576px) {
      left: 2rem;
    }
  }
  .fancybox-button--arrow_right {
    right: 5rem;
    transform: rotate(180deg);
    @media all and (max-width: 576px) {
      right: 2rem;
    }
  }
}
