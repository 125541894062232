@import "colors";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 10;
}
.header.header--sticky {
  box-shadow: 0 .5rem 2rem rgba(0, 0, 0, 0.09);
  transition: box-shadow .5s;
}

.header__top-panel {
  height: 10rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.6rem 0 5.8rem;
  @media all and (max-width: 1200px) {
    padding: 0 3rem;
  }
  @media all and (max-width:992px) {
    padding: 0;
  }
  @media all and (max-width:576px) {
    height: 7.2rem;
  }
}

.header__left-block {
  width: 29rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media all and (max-width: 1260px) {
    width: 13rem;
  }
  @media all and (max-width: 992px) {
    width: 35rem;
  }

  .left-block__logo-img {
    max-width: 12.4rem;
    margin-top: .7rem;
    margin-right: 4.7rem;
    @media all and (max-width: 576px) {
      max-width: 9rem;
      margin-right: 4.8rem;
    }
  }

  .left-block__desc {
    max-width: 12.4rem;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.3rem;
    color: $text-dark-gray;
    @media all and (max-width: 1260px) {
      display: none;
    }
    @media all and (max-width: 992px) {
      display: unset;
    }
    @media all and (max-width: 576px) {
      max-width: 9.7rem;
      font-size: 1.4rem;
      line-height: 1.8rem;
    }

  }
}

.left-block__menu-caller {
  display: none;
  width: 3rem;
  height: 3rem;
  margin-right: 3rem;
  justify-content: center;
  align-items: center;

  @media all and (max-width: 1220px) {
    display: flex;
  }

  @media all and (max-width: 576px) {
    margin-right: 1rem;
  }
  .menu-caller__burger {
    position: relative;
    width: 2rem;
    height: .2rem;
    background: $text-black;

    @media all and (max-width: 576px) {
      margin-left: -.5rem;
    }
    @media all and (max-width: 576px) {
      width: 1.4rem;
    }
    &::before, &::after {
      content: '';
      display: block;
      width: 2rem;
      height: .2rem;
      background: $text-black;
      position: absolute;
      left: 0;
      @media all and (max-width: 576px) {
        width: 1.4rem;
      }
    }
    &::before {
      top: -.7rem;
      @media all and (max-width: 576px) {
        top: -.5rem;
      }
    }
    &::after {
      width: 1rem;
      bottom: -.7rem;
      @media all and (max-width: 576px) {
        width: .7rem;
        bottom: -.5rem;
      }
    }

  }
}

.header__right-block {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1.9rem;
  line-height: 2.4rem;

  .navigation__bg-navigation {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $t-bg-footer;
    opacity: 0;
    transition: .2s;
  }
  .navigation__bg-navigation.bg-navigation--active {
    display: block;
    opacity: .5;
    z-index: 1;
  }
  .navigation__menu {
    display: flex;
    align-items: center;
    @media all and (max-width: 1220px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: fixed;
      top: 0;
      left: 0;
      max-width: 37.5rem;
      width: 100%;
      height: 100%;
      padding: 3.2rem 4rem;
      background: $t-bg-footer;
      z-index: 10;
      transition: .3s;
      transform: translateX(-37.5rem);
      overflow-y: scroll;
    }
    @media all and (max-width: 375px) {
      transform: translateX(-100%);
    }

    .menu__item + .menu__item {
      margin-left: 3.4rem;
      @media all and (max-width: 1360px) {
        margin-left: 1.9rem;
      }
    }
    .menu__item {
      position: relative;
      padding: 2.4rem 0;

      @media all and (max-width: 1060px) {
        padding: 0;
      }

      &:hover {
        & > .submenu {
          @media all and (min-width: 1220px) {
            display: block;
          }
        }
      }
    }

    .submenu {
      display: none;
      position: absolute;
      width: max-content;
      top: 100%;
      left: -2rem;
      background: white;
      box-shadow: 0 4rem 10rem rgba(31, 39, 40, 0.18);

      &__item {
        font-weight: 400;
        padding: 1.6rem 2.3rem;
        border-top: .1rem solid #EAEFF0;

        &:first-of-type {
          border: none;
        }
      }

    }
    .menu__language-select {
      display: none;
    }
    .item__link {
      color: $text-black;
      transition: .3s;



      &:hover {
        color: $t-green-hover;
      }
    }

    .menu__close-btn {
      display: none;
      position: absolute;
      top: 3rem;
      right: 3rem;
      width: 2.5rem;
      height: 2.5rem;
      font-size: 0;
      &::before, &::after {
        content: '';
        display: block;
        width: 1.8rem;
        height: .2rem;
        position: absolute;
        top: calc(50% - .1rem);
        left: calc(50% - .9rem);
        background: white;
      }
      &:before {
        transform: rotate(-45deg);
      }
      &:after {
        transform: rotate(45deg);
      }
    }
  }

  .navigation__menu.navigation--active {
    transform: translateX(0);
    .menu__item {
      display: block;
      margin: 2rem 0 0 0;
      .item__link {
        font-size: 1.8rem;
        line-height: 2.3rem;
        color: white;
      }
    }
      .menu__language-select + .menu__language-select {
        margin-top: 1rem;
      }
      .menu__language-select {
      margin-top: 5.4rem;
      position: relative;
      display: block;
      color: white;

      .language-select__button {
        position: relative;
        background: transparent;
        border: none;
        border-radius: 0;
        font-size: 1.9rem;
        line-height: 2.4rem;
        color: white;
        padding-left: 2.7rem;

        &::before {
          content: '';
          display: block;
          width: 1.9rem;
          height: 1.9rem;
          position: absolute;
          left: 0;
          top: calc(50% - .95rem);
          background: url('../img/globe-white.svg') center center no-repeat;
          background-size: contain;
          opacity: .4;
        }
      }
    }

    .menu__language-select.current-language {
      .language-select__button {
        &::before {
          background: url('../img/globe.svg') center center no-repeat;
          background-size: contain;
          opacity: 1;
        }
      }

    }
    .menu__close-btn {
      display: block;
    }
  }
}


.right-block__selection-language {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 15.7rem;
  margin-left: 3.6rem;
  @media all and (max-width: 1360px) {
    width: 13.7rem;
  }
  @media all and (max-width: 992px) {
    margin-right: 2rem;
  }
  @media all and (max-width: 576px) {
    display: none;
  }

  .selection-language__language-menu {
    position: absolute;
    top: 2.2rem;
    display: flex;
    width: 16.4rem;
    padding-right: .7rem;
    background: white;
    flex-direction: column;
    justify-content: center;

    @media all and (max-width: 1220px) {
      right: 2rem;
    }
  }
  .selection-language__language-menu.language-menu--active {
    box-shadow: 0 .7rem 2rem rgba(0, 0, 0, 0.07), 0 0 .1rem rgba(0, 0, 0, 0.05);
  }
  .language-menu__item {
    order: 2;
    display: none;
    position: relative;
    padding: 0;
    width: max-content;
    user-select: none;

    .item__language-button {
      position: relative;
      background: transparent;
      border: none;
      border-radius: 0;
      font-weight: 600;
      font-size: 1.9rem;
      line-height: 2.4rem;
      padding: 1.5rem 1.6rem 1.6rem 5.1rem;
      cursor: pointer;

      &::before {
        content: '';
        display: block;
        width: 2.3rem;
        height: 2.3rem;
        position: absolute;
        left: 2rem;
        background: url('../img/globe-gray.svg') center center no-repeat;
        background-size: contain;
      }
    }
  }
  .language-menu__item.item--show {
    display: flex;
  }
  .language-menu__item.item--current {
    order: 1;
    display: flex;
    align-items: center;

    .item__language-button {
      &::before {
        background: url('../img/globe.svg') center center no-repeat;
        background-size: contain;
      }
    }

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      top: 2.5rem;
      right: 0;
      border: .4rem solid transparent;
      border-top: .4rem solid $text-black;
      transition: .3s;
    }
  }
  .language-menu__item:hover {
    &::after {
      border-top: .4rem solid $t-green;
    }
  }
}
