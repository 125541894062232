@import "colors";

.content-block__news-list {
  margin-top: 2.3rem;
  margin-bottom: 7.5rem;
  @media all and (max-width: 576px) {
    margin-top: 1.2rem;
    margin-bottom: 3.5rem;
  }


  .news-list__load-more {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
    margin-top: 4.1rem;
    height: 5.6rem;
    @media all and (max-width: 576px) {
      margin-top: 3.5rem;
    }
  }
}
.news-list__news-item.news-item--visible {
  display: flex;
  @media all and (max-width: 576px) {
    flex-direction: column;
  }
}

.news-list__news-item {
  margin-top: 5.1rem;
  display: none;
  padding-bottom: 4.5rem;
  border-bottom: .1rem solid #E0E2E3;

  @media all and (max-width: 576px) {
    margin-top: 3rem;
    padding-bottom: 2.6rem;
  }

  .news-item__date {
    padding-top: 1rem;
    min-width: 14.4rem;
    font-size: 1.6rem;
    line-height: 1.8rem;
    @media all and (max-width: 576px) {
      padding-top: 0;
      font-size: 1.4rem;
      line-height: 1.6rem;
    }
  }

  .news-item__news-preview {

    .news-preview__link {
      color: $text-black;
      transition: .2s;
      &:hover {
        color: $t-green-hover;
      }
      &:visited {
        color: $text-black;
      }
    }
    .news-preview__title {
      font-size: 2.6rem;
      line-height: 3rem;
      font-weight: 700;

      @media all and (max-width: 576px) {
        display: inline-block;
        margin-top: 1rem;
        font-size: 2.2rem;
        line-height: 2.7rem;
      }
    }

    .news-preview__desc {
      margin-top: 1.9rem;
      @media all and (max-width: 576px) {
        margin-top: 1.4rem;
      }
    }
  }
}


