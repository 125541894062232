@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $font-family;
    src: url('#{$file-path}.woff2');
    font-weight: $weight;
    font-style: $style;
  }
}
@include font-face("FuturaDemic", "../fonts/futuraDemiC", 300);
@include font-face("FuturaPT", "../fonts/FuturaPT-Light", 300);
@include font-face("FuturaPT", "../fonts/FuturaPT-Book", 400);
@include font-face("FuturaPT", "../fonts/FuturaPT-Medium", 600);
@include font-face("FuturaPT", "../fonts/FuturaPT-Demi", 700);
@include font-face("FuturaPT", "../fonts/FuturaPT-Bold", 800);

