@import "colors";
@import "fonts";

.main-screen {
  .container {
    @media all and (max-width: 768px) {
      padding: 0;
    }
  }
}

.main-screen__wrapper {
  margin-top: 10rem;
  position: relative;
  overflow: hidden;

  @media all and (max-width: 576px) {
    margin-top: 7.2rem;
  }

  .wrapper__main-bg {
    position: relative;
    height: 54rem;
    background-size: cover;
    overflow: hidden;

    @media all and (max-width: 768px) {
      height: 36.8rem;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: radial-gradient(69.79% 69.72% at 50% 50%, rgba(2, 37, 39, 0) 0%, #022527 100%);
      opacity: 0.6;
      z-index: 1;
      mix-blend-mode: multiply;
    }

    .main-bg__img {
      opacity: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: .3s;

      &.img--show {
        opacity: 1;
      }
    }

  }

  .wrapper__main-info {
    position: absolute;
    bottom: 7.7rem;
    right: calc(50% - 53.3rem);
    height: max-content;
    width: 28.5rem;
    z-index: 3;

    @media all and (max-width: 1200px) {
      right: 6rem;
    }
    @media all and (max-width: 1024px) {
      width: 20rem;
      right: 3rem;
    }
    @media all and (max-width: 768px) {
      width: 15rem;
    }
    @media all and (max-width: 480px) {
      display: flex;
      justify-content: center;
      width: 100%;
      right: 0;
      bottom: 5.8rem;

    }

    &-item {
      position: absolute;
      bottom: 0;
      left: -20px;
      transform: translateX(47rem);
      transition: .3s;
      @media all and (max-width: 480px) {
        left: calc(50% - 5.6rem);
        transform: translateX(35rem);
      }


      &.item--show {
        transform: translateX(0);
        transition-delay: .3s;
        opacity: 1;
      }
    }

    &-desc {
      font-size: 2.4rem;
      line-height: 3rem;
      color: rgba(255, 255, 255, .8);

      @media all and (max-width: 1024px) {
        font-size: 1.8rem;
        line-height: 2.4rem;
      }
      @media all and (max-width: 768px) {
        display: none;
      }
    }
    &-button {
      margin-top: 2.8rem;
      display: flex;
      height: 4.5rem;
      width: 17.8rem;
      //width: auto;
      align-items: center;
      justify-content: center;
      background: transparent;
      color: white;
      border: .2rem solid rgba(255, 255, 255, .8);

      @media all and (max-width: 1024px) {
        margin-top: 1.8rem;
      }
      @media all and (max-width: 768px) {
        margin-top: unset;
      }

      @media all and (max-width: 480px) {
        height: 3.1rem;
        width: 12rem;
        font-size: 1.24rem;
        line-height: 2rem;
        border: .1rem solid rgba(255, 255, 255, .8);
      }

      &:hover {
        background: transparent;
      }

    }
  }

  .wrapper__services-slider {
    position: absolute;
    top: calc(50% - 20rem);
    left: 8.5rem;
    display: flex;
    align-items: center;
    height: 40rem;
    overflow: hidden;
    z-index: 2;

    @media all and (max-width: 1200px) {
      left: 6rem;
    }
    @media all and (max-width: 768px) {
      top: calc(50% - 14rem);
      height: 28rem;
    }
    @media all and (max-width: 640px) {
      left: 4rem;
    }
    @media all and (max-width: 480px) {
      left: 0;
      top: 10rem;
      height: 14rem;
    }

    &:focus {
      outline: none;
    }

    .slick-list {
      overflow: unset;
    }
    .services-slider__slider-item.slick-slide {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0;
      color: white;

      &:focus {
        outline: none;
      }

      &.slick-active {
        .slider-info__link {
          color: rgba(255, 255, 255, .8);
        }
      }

      &.slick-center {
        .slider-info__title {
          height: 3.6rem;
          position: absolute;
          display: flex;
          align-items: center;
          top: 0;
          left: 0;
          font-size: 7rem;
          line-height: 8.2rem;

          @media all and (max-width: 1200px) {
            font-size: 5rem;
            line-height: 6.8rem;
          }
          @media all and (max-width: 768px) {
            font-size: 4rem;
            line-height: 5rem;
          }
          @media all and (max-width: 640px) {
            font-size: 3.2rem;
            line-height: 4rem;
          }
          @media all and (max-width: 480px) {
            left: unset;
            height: 2.5rem;
          }
          @media all and (max-width: 420px) {
            font-size: 2.8rem;
            line-height: 3.3rem;
          }
          @media all and (max-width: 375px) {
            font-size: 2.4rem;
            line-height: 3rem;
          }

        }
        .slider-info__link {
          font-weight: 700;
          color: white;
        }
      }
    }
  }

  .wrapper__aria-slider-scroll {
    display: block;
    position: absolute;
    z-index: 9;
    width: 50rem;
    height: 100%;
    top: 0;
    left: 8.5rem;

    @media all and (max-width: 1200px) {
      width: 40rem;
      left: 6rem;
    }
    @media all and (max-width: 900px) {
      left: 0;
    }
    @media all and (max-width: 768px) {
      width: 30rem;
    }
    @media all and (max-width: 480px) {
      width: 30rem;
      left: calc(50% - 15rem);
      height: 15rem;
      top: 10rem;
    }
  }
}

.services-slider__slider-item {
  margin: 1.1rem 0;
  @media all and (max-width: 480px) {
    margin: .2rem 0;
  }

  .slider-item__slider-info {
    width: 85rem;
    height: 3.6rem;
    position: relative;
    display: flex;
    align-items: center;

    @media all and (max-width: 768px) {
      height: 3rem;
    }
    @media all and (max-width: 480px) {
      width: 100%;
      justify-content: center;
      height: 2.5rem;
    }
  }

  .slider-info__link {
    color: rgba(255, 255, 255, .4);

    &:hover {
      color: rgba(255, 255, 255, .4);
    }
    &:focus {
      outline: none;
    }
  }

  .slider-info__title {
    font-size: 3rem;
    line-height: 3.4rem;
    transition: .3s;

    @media all and (max-width: 1024px) {
      font-size: 2.4rem;
      line-height: 3rem;
    }
    @media all and (max-width: 768px) {
      font-size: 1.8rem;
      line-height: 2.4rem;
    }
    @media all and (max-width: 480px) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    @media all and (max-width: 375px) {
      font-size: 1.2rem;
    }
  }
  .slider-info__desc {
    margin-top: 2rem;
    font-size: 1.8rem;
    line-height: 2.8rem;
    @media all and (max-width: 576px) {
      margin-top: 1.2rem;
    }
  }
  .slider-item__link {
    width: max-content;
    margin-top: 3.8rem;
    display: inline-block;
    height: 5.6rem;

    @media all and (max-width: 576px) {
      display: none;
    }
    &:hover {
      background: white;
      color: $t-green;
    }
  }
}

.wrapper__services-slider {
  .slick-dots {
    width: 3rem;
    top: 35%;
    left: -7.3rem;
    @media all and (max-width: 992px) {
      left: -6rem;
    }
    @media all and (max-width: 768px) {
      width: 100%;
      top: unset;
      bottom: -4.5rem;
      left: unset;
    }
    li {
      display: block;
      margin-bottom: 2.2rem;
      @media all and (max-width: 768px) {
        display: inline-block;
        margin-bottom: 0;
      }
      button {
        &::before {
          content: '';
          width: .9rem;
          height: .9rem;
          background: transparent;
          border-radius: 50%;
          border: .5rem solid white;
          opacity: 1;

          @media all and (max-width: 768px) {
            border: .5rem solid $t-border-gray;
          }
          @media all and (max-width: 576px) {
            width: .6rem;
            height: .6rem;
            border: .4rem solid $t-border-gray;
          }
        }
      }
    }
    li.slick-active {
      button {
        &::before {
          border: .5rem solid #45966E;
          @media all and (max-width: 576px) {
            border: .4rem solid #45966E;
          }
        }
      }
    }
  }
}


