@import "fonts";
@import "colors";

body {
  font-family: 'FuturaPT', 'Sans-Serif';
  font-size: 2.1rem;
  line-height: 3.2rem;
  @media all and (max-width: 576px) {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
}

.text-center {
  text-align: center;
}

.text-nowrap {
  white-space: nowrap;
}

.text-break {
  display: block;
}

.block-title, .page-title {
  font-family: 'FuturaPT','Sans-Serif';
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 5.6rem;
  text-align: center;

  @media all and (max-width: 576px) {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}
.page-title {
  text-align: left;
}
