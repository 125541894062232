@import "normalize";
@import "fonts";
@import "colors";

@import "css/jquery.fancybox";
@import "css/slick";
@import "css/slick-theme";

html {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}


body.no-scroll {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: sticky;
}

.footer {
  margin-top: auto;
}

button:focus {
  outline: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  text-decoration: none;
  color: $t-link;
  &:hover {
    color: $t-link-hover;
  }
}

ul {
  list-style: none;
}
.container {
  width: 144rem;
  padding: 0 3.7rem;
  margin: 0 auto;
  @media all and (max-width: 1440px) {
    width: 100%;
  }
  @media all and (max-width: 768px) {
    padding: 0 2rem;
  }
}

.block-container {
  width: 113.3rem;
  padding: 0;
  margin: 0 auto;
  @media all and (max-width: 1260px) {
    padding: 0 3rem;
    width: 100%;
  }
  @media all and (max-width: 768px) {
    padding: 0 2rem;
  }
}

.responsive-img {
  width: 100%;
}

.button {
  background: $t-green;
  font-size: 1.8rem;
  line-height: 2.9rem;
  font-weight: 700;
  height: 5.2rem;
  border: none;
  border-radius: 0;
  white-space: nowrap;
  cursor: pointer;
  color: white;
  transition: .2s;

  &:hover {
    background: $t-green-hover;
  }

  &:active {
    background: $t-green-active;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: $t-green;
    color: $text-transparent;
  }
}

.button.button-invert {
  font-weight: 700;
  background: white;
  color: $text-black;
  border: 1px solid $t-green;
  padding: 1.3rem 3.5rem 1.5rem;
  &:hover {
    background: $t-green;
    color: white;
    @media all and (max-width: 768px){
      background: white;
      color: $text-black;
    }
  }
  &:active {
    color: white;
    background: $t-green-active;
    @media all and (max-width: 768px){
      background: white;
      color: $text-black;
    }
  }
  &:disabled {
    color: $text-gray;
    border-color: $t-border-gray;
  }
}
.button.button-invert:disabled {
  &:hover {
    background: white;
    color: $text-gray;
  }
}
.grecaptcha-badge {
  display: none;
}







