@import "colors";

.globally {
  margin-top: 4.2rem;

  .container {
    @media all and (max-width: 992px) {
      padding: 0;
    }
  }

  .block-container {
    @media all and (max-width: 992px) {
      padding: 0;
    }
  }

  &__inner {
    position: relative;
    padding: 7.9rem 0 3rem;
    background: #F0F4F4;
    overflow: hidden;

    @media all and (max-width: 992px) {
      padding-top: 6rem
    }
    @media all and (max-width: 768px) {
      padding-top: 4.6rem
    }

    @media all and (max-width: 576px) {
      padding: 3.8rem 0;
    }
    @media all and (max-width: 375px) {
      padding-bottom: 2.7rem;
    }
  }

  &__title {
    position: relative;
    z-index: 2;
  }

  &__world-map {
    position: relative;
    z-index: 1;
    margin-top: -8.5rem;

    @media all and (max-width: 768px) {
      margin-top: 0;
    }
    @media all and (max-width: 576px) {
      margin-top: .5rem;
      width: calc(100% + 7rem);
      margin-left: -3.5rem;
    }


    &-img {
      max-width: 100%;
      vertical-align: top;
    }
  }

  &__country-list {
    display: none;

    @media all and (max-width: 576px) {
      display: block;
      width: 100%;
    }

    &-item {
      width: 100%;
      text-align: center;
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.6rem;
    }
     &-item +  &-item {
       margin-top: .7rem;
     }
  }
}
