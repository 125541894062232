@import "colors";

.content-block__documents {
  margin-top: 6.5rem;
  margin-bottom: 12.8rem;
  display: flex;
  flex-wrap: wrap;
  @media all and (max-width: 768px) {
    margin-bottom: 8rem;
  }
  @media all and (max-width: 576px) {
    margin-top: 3.6rem;
    margin-bottom: 2.8rem;
  }
}

.documents__document-item:last-child {
  @media all and (max-width: 576px) {
    margin-bottom: 0;
  }
}

.documents__document-item {
  display: flex;
  width: 50%;
  margin-bottom: 4.3rem;

  @media all and (max-width: 576px) {
    width: 100%;
    margin-bottom: 3.5rem;
  }
  .document-item__icon {
      margin-right: 2rem;
      min-width: 3.7rem;
      max-width: 3.7rem;
    }
    .document-item__info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .info__link {
        font-weight: 600;
        line-height: 2.3rem;
        border-bottom: 1px solid $t-link;

        @media all and (max-width: 768px) {
          border-bottom: none;
          text-decoration: underline;
        }
        @media all and (max-width: 576px) {
          line-height: 2.1rem;
        }
      }
      .info__size {
        font-size: 1.5rem;
        line-height: 2.3rem;
        color: #919191;
        @media all and (max-width: 576px) {
          margin-top: .8rem
        }
      }
    }
}
