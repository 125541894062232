@import "colors";

.efficiency {
  margin-bottom: 4.2rem;

  @media all and (max-width: 576px) {
    margin-bottom: 0;
  }

  &__tank {
    background-color: #EFF3F4;
    padding: 8rem 0 7.1rem;
    margin-bottom: 3.5rem;

    @media all and (max-width: 480px) {
      width: calc(100% + 4rem);
      margin-left: -2rem;
      padding: 2.7rem 0 4.3rem;
      margin-bottom: 0;
    }

    .block-title {
      font-size: 3.2rem;
      margin-left: 17.6rem;
      text-align: left;
      color: #000;
      line-height: 4.9rem;

      @media all and (max-width: 992px) {
        margin-left: 0;
      }
     @media all and (max-width: 480px) {
        display: none;
      }
    }

    .slider-navigation {
      margin-top: 5.4rem;

      @media all and (max-width: 576px) {
        margin-top: 1.8rem;
      }
    }

    .slick-slide img {
      width: 76%;
      margin: 0 auto;

      @media all and (max-width: 576px) {
        width: 100%;
      }
    }
    .efficiency__inner {
      background-image: unset;
    }

    .slider-navigation__arrow {
      background-color: #B4C1C3;
      transition: 300ms;
      border: unset;
      color: #fff;

      &:hover {
        transition: 300ms;
        background-color: #00AE7A;
      }
    }
  }

  &__inner {
    position: relative;
    max-width: 136.6rem;
    margin: 0 auto ;
    padding: 8rem 3.7rem 6.4rem;
    background: url('../img/efficiency/efficiency-bg.jpg') center center / cover no-repeat;

    @media all and (max-width: 1200px) {
      padding: 8rem 0 6.4rem;
    }
    @media all and (max-width: 576px) {
      padding: 5.8rem 0 4rem;
    }
    @media all and (max-width: 375px) {
      width: calc(100% + 4rem);
      margin-left: -2rem;
      padding: 3.8rem 0 2.8rem;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, .5);
      z-index: 1;
    }
  }

  &__title {
    position: relative;
    color: white;
    z-index: 2;

  }

  &__slider {
    position: relative;
    z-index: 2;
    margin-top: 8.5rem;

    @media all and (max-width: 576px) {
      margin-top: 4.8rem;
    }
  }

  .slider-item {

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 27.5rem;
      margin: 0 auto;
      text-align: center;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 9.4rem;
      height: 9.4rem;
      background: $t-green;
      border-radius: 50%;
    }

    &__title {
      margin-top: 2rem;
      display: inline-block;
      font-weight: 700;
      font-size: 2.8rem;
      line-height: 3.2rem;
      color: white;

      @media all and (max-width: 576px) {
        max-width: 28rem;
        font-size: 2.4rem;
        line-height: 2.6rem;
      }

    }

    &__desc {
      max-width: 25.3rem;
      margin-top: 1.6rem;
      font-size: 2.1rem;
      line-height: 3.2rem;
      color: rgba(255, 255, 255, .7);

      @media all and (max-width: 576px) {
        max-width: 21rem;
        font-size: 1.8rem;
        line-height: 2.8rem;
      }
    }

    .slick-arrows {

    }
  }
}
